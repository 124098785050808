import React, {Component} from "react";
import {Link} from "gatsby";
import H3 from "../headings/h3";
import Img from 'gatsby-image';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import './CardHeader.scss';

class CardHeader extends Component {
    render() {
        return (
            <>
                { this.props.image !== undefined && this.props.image != null && this.props.image !== '' ? (
                        <div className="card-media">
                        {this.props.link != null ?
                            <Link to={this.props.link}
                              onClick={e => {
                                  // Lets track that custom click
                                  trackCustomEvent({
                                      // string - required - The object that was interacted with (e.g.video)
                                      category: "Title Link",
                                      // string - required - Type of interaction (e.g. 'play')
                                      action: "click",
                                      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                      label: this.props.title,
                                      // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                      value: this.props.nid
                                  })
                                  return true;
                              }}
                            ><Img fluid={this.props.image.childImageSharp.fluid} /></Link> :
                            <Img fluid={this.props.image.childImageSharp.fluid} /> }
                        </div>
                    )
                    : ''
                }

                <div className={'card-header'}>
                    <H3>{this.props.link != null ? <Link to={this.props.link}
                         onClick={e => {
                             // Lets track that custom click
                             trackCustomEvent({
                                 // string - required - The object that was interacted with (e.g.video)
                                 category: "Portfolio Title Link",
                                 // string - required - Type of interaction (e.g. 'play')
                                 action: "click",
                                 // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                 label: this.props.title,
                                 // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                 value: this.props.nid
                             })
                             return true;
                         }}
                    >{this.props.title}</Link>:this.props.title}</H3>
                </div>
            </>
        )
    };
};

export default CardHeader;
