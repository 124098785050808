import React from "react";
import './CardBody.scss';

const CardBody = (props) => {
    return (
        <div className={'card-body'} dangerouslySetInnerHTML={{__html:props.content}} />
    );
};

export default CardBody;
