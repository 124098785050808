import React, {Component} from "react";
import CardItem from "../../molecules/CardItem/CardItem";
import Section from "../../atoms/Section/Section";
import {currentLanguageByPath} from "../../../helpers/MainHelper";

class BlogList extends Component {
    render() {
        const blogitems = this.props.data.drupal.blogs.items;

        const cards = blogitems.map((entity) => {
            let blog = entity.nl;
            if (currentLanguageByPath() === 'en') {
                blog = entity.en;
            }

            if (blog === null) {
                return '';
            }
            let blog_path = blog.path.alias;

            if (currentLanguageByPath() === 'en') {
                blog_path = '/en' + blog_path;
            }
            let image = null;
            if (blog.fieldMediaImage.length > 0) {
                image = blog.fieldMediaImage[0].gatsbyImageFile;
            }

            return (
                <CardItem
                    key={blog.nid}
                    nid={blog.nid}
                    class={'blog-item'}
                    title={blog.title}
                    content={blog.body}
                    link={blog_path}
                    image={image}
                />
            );
        });

        return (
            <Section className={'section-cards'}>
                {cards}
            </Section>
        )
    };
}

export default BlogList;
