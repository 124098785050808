import React, {Component} from "react";
import CardFooter from "../../atoms/CardComponents/CardFooter";
import CardHeader from "../../atoms/CardComponents/CardHeader";
import CardBody from "../../atoms/CardComponents/CardBody";

import './Card.scss';
class Card extends Component {
    render() {
        return (
            <article className={'card-item'}>
                <CardHeader
                    nid={this.props.nid}
                    title={this.props.title}
                    link={this.props.link}
                    image={this.props.image}
                />
                <CardBody
                    content={this.props.content}
                />
                <CardFooter/>
            </article>
        )
    }
}

export default Card;
