import * as React from "react";

const H3 = ({className, children}) => (
    <h3 className={className}>{children}</h3>
);

H3.defaultProps = {
    className: '',
    children: '',
};

export default H3;