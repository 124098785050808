import React, {Component} from "react";
import Card from "./Card";

class CardItem extends Component {
    render() {
        return (
        <div className={'card-container' + (this.props.cardWidth === 100 ? ' full-width' : '') }>
            <Card
                nid={this.props.nid}
                link={this.props.link}
                title={this.props.title}
                content={this.props.content}
                image={this.props.image}
            />
        </div>
        )
    }
}

export default CardItem;
